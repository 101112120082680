import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { FaAngleDoubleRight } from "react-icons/fa"
import Title from "./Title"

export const query = graphql`
  {
    allStrapiJobs(sort: { fields: strapiId, order: DESC }) {
      nodes {
        company
        date
        id
        position
        job_description {
          name
          id
        }
      }
    }
  }
`

const Jobs = () => {
  const {
    allStrapiJobs: { nodes: jobs },
  } = useStaticQuery(query)
  const [index, setIndex] = React.useState(0)
  const { company, position, date, job_description } = jobs[index]

  return (
    <section className="section jobs">
      <Title title="experience" />
      <div className="jobs-center">
        <div className="btn-container">
          {jobs.map((job, jIndex) => {
            return (
              <button
                onClick={() => setIndex(jIndex)}
                key={jIndex}
                className={`job-btn ${index === jIndex && "active-btn"}`}
              >
                {job.company}
              </button>
            )
          })}
        </div>
        <article className="job-info">
          <h3 className="">{position}</h3>
          <h4 className="">{company}</h4>
          <p className="job-date">{date}</p>
          <div className="job-desc" key={job_description.id}>
            <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
            <p className="">{job_description.name}</p>
          </div>
        </article>
      </div>
      <Link to="/about" className="btn center-btn">
        more info
      </Link>
    </section>
  )
}

export default Jobs
