import { graphql } from "gatsby"
import React from "react"
import Blogs from "../components/Blogs"
import Header from "../components/Header"
import Jobs from "../components/Jobs"
import Layout from "../components/Layout"
import Projects from "../components/Projects"
import Seo from "../components/Seo"
import Services from "../components/Services"

const IndexPage = ({ data }) => {
  const {
    allStrapiProjects: { nodes: projects },
    allStrapiBlogs: { nodes: blogs },
  } = data

  return (
    <Layout>
      <Seo title="Home" description="About AAz" />
      <Header />
      <Services />
      <Jobs />
      <Projects projects={projects} title="Featured Projects" showLink />
      <Blogs blogs={blogs} title="blog" showLink />
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiProjects(filter: { featured: { eq: true } }) {
      nodes {
        description
        featured
        id
        strapiId
        title
        url
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        stack {
          id
          title
        }
      }
    }
    allStrapiBlogs(sort: { fields: date, order: DESC }) {
      nodes {
        category
        date(formatString: "MMMM DD, YYYY")
        description
        id
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        slug
        title
      }
    }
  }
`

export default IndexPage
