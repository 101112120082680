import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { MySocialLinks } from "../constants/MySocialLinks"

const Header = () => {
  return (
    <header className="hero">
      <div className="section-center hero-center">
        <article className="hero-info">
          <div>
            <div className="underline"></div>
            <h1>i'm amir</h1>
            <h4>frontend web developer</h4>
            <Link to="/contant" className="btn">
              contact me
            </Link>
            <MySocialLinks />
          </div>
        </article>
        <StaticImage
          className="hero-img"
          src="../images/portfolio-aaz.JPG"
          alt="Aaz"
          placeholder="blurred"
          layout="fixed"
          width={400}
          height={400}
        />
      </div>
    </header>
  )
}

export default Header
